<template>
    <b-modal
        :title="$t('chat.edit-message')"
        :visible="visible"
        size="lg"
        hide-footer
        @change="$emit('change', false)"
    >        
        <div>
            <h5>
                {{ $t('chat.old-message') }}
            </h5>
            <p v-html="oldMessageContent">
            </p>
        </div>

        <div style="margin-top: 1rem; margin-bottom: 2rem;">
            <h5>
                {{ $t('chat.new-message') }}
            </h5>
            <b-form
                class="form-send-message d-flex justify-content-between align-items-center"
            >
                <b-form-input 
                    v-model="newMessage" 
                    class="mr-1" 
                    placeholder="Type your message here" 
                />
                <div class="message-actions d-flex align-items-center">
                <!-- <b-button variant="primary" type="submit">
                    <span class="align-middle d-md-inline-block d-none">
                    Send
                    </span>
                </b-button> -->
                </div>
            </b-form>
        </div>

        <!-- button-->
        <div class="d-flex justify-content-center mb-2 flex-wrap">
            <b-button
                variant="primary"
                target="_blank"
                class="mt-25"
                @click="handleEditItem()"
            >
                {{ $t('common.save') }}
            </b-button>
        </div>
    </b-modal>
</template>

<script>


export default {
    name: 'EditMessageModal',
    model: {
        prop: 'visible',
        event: 'change',
    },
    mixins: [],
    props: {
        visible: Boolean,
        message: {
        type: Object,
        default: () => {},
        },
    },
    data() {
        return {
            newMessage: '',
            messageToEmit: null,
        };
    },
    computed: {
        locale() {
        return this.$store.state.locale.currentLocale;
        },
        currentCollective() {
        return this.$store.getters.currentCollective;
        },
        oldMessageContent() {
            return this.message?.message;
        },
        oldMessageContentScaped() {
            if(!this.oldMessageContent){
                return '';
            }
            let chatInputMessageWithoutSpaces = this.oldMessageContent;
            const wordsToReplaceByNothing = ['<p>', '</p>'];
            wordsToReplaceByNothing.forEach((word) => {
                chatInputMessageWithoutSpaces = chatInputMessageWithoutSpaces.replaceAll(word, '');
            });
            chatInputMessageWithoutSpaces = chatInputMessageWithoutSpaces.replaceAll('<br>', '\n');

            return chatInputMessageWithoutSpaces;
        },

    },
    created() {
        // this.newMessage = this.oldMessageContentScaped;
        // console.log('newMessage:', this.newMessage);
    },
    message: {
        immediate: true,
        handler(newVal) {
        this.newMessage = this.oldMessageContentScaped;
        }
    },
    methods: {
        handleEditItem() {
            console.log('handleEditItem', this.message);
            if (this.message && this.newMessage) {
                // emit edit message
                // this.messageToEmit = this.message;
                this.message.message = this.newMessage;
                console.log('emitting edit-item message:', this.message);
                // emit message with text changed
                this.$emit('edit-item', this.message);
            }
            this.$emit('change', false);
        },
    },
};
</script>
